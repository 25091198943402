import './styles/quasar.scss'
import lang from 'quasar/lang/ja.js'
import '@quasar/extras/material-icons/material-icons.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import Meta from 'quasar/src/plugins/Meta.js';import Notify from 'quasar/src/plugins/Notify.js';import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';import SessionStorage from 'quasar/src/plugins/SessionStorage.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Dialog,
    Loading,
    LocalStorage,
    Meta,
    Notify,
    SessionStorage,
  },
  lang: lang,
}
