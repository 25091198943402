import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../components/ListTable.vue'),
    props: { category: 1 },
  },
  {
    path: '/movie-korea',
    component: () => import('../components/ListTable.vue'),
    props: { category: 2 },
  },
  {
    path: '/movie-general',
    component: () => import('../components/ListTable.vue'),
    props: { category: 3 },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
