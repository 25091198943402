<template>
  <q-layout view="lHh Lpr lFf">
    <q-header class="text-grey">
      <q-tabs
        v-model="tab"
        outside-arrows mobile-arrows inline-label
        align="left"
        active-color="white"
        class="bg-grey-10 shadow-2"
      >
        <q-route-tab class="q-px-lg" to="/" label="韓国ドラマ" />
        <q-route-tab class="q-px-lg" to="/movie-korea" label="韓国映画" />
        <q-route-tab class="q-px-lg" to="/movie-general" label="一般映画" />
      </q-tabs>
    </q-header>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'LayoutDefault',

  components: {
  },

  setup() {
    return {
      tab: ref(''),
      leftDrawerOpen: ref(false),
    };
  },
};
</script>
