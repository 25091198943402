import { createStore } from 'vuex'

type State = {
  loading: boolean
  token: string | null
  version: string | null
  lastModified: Date | null
  view: string | null
}
const TOKEN_KEY = 'SKWONDOCS'

export default createStore({
  state: {
    loading: false,
    token: localStorage.getItem(TOKEN_KEY) ?? null,
    version: null,
    lastModified: null,
    view: null,
  },

  getters: {
    view: (state: State) => state.view ?? 'DVD一覧',
  },

  mutations: {
    loading(state: State, value: boolean) {
      state.loading = value
    },

    token(state: State, token: string | null) {
      if (token) {
        localStorage.setItem(TOKEN_KEY, token)
      } else {
        localStorage.removeItem(TOKEN_KEY)
      }
      state.token = token
    },

    version(state: State, data) {
      state.version = data.version
      state.lastModified = data.lastModified
    },

    view(state: State, view: string) {
      state.view = view
    },
  },

  actions: {},
  modules: {},
})
